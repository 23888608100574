import { createRouter, createWebHistory } from 'vue-router'
// 使用动态路由更方便，且可以实现权限控制
const routes = [
  {
    path: '/',
    name: 'ERP',
    component: () => import('../layout'),
    redirect: '/login',
    children: [
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/index/index'),
        meta: {
          keepAlive: true,
        }
      },
      {
        path: '/userCenter',
        name: '个人中心',
        component: () => import('../views/userCenter/index'),
        meta: {
          keepAlive: true,
        }
      },
      {
        path: '/preview',
        name: '文档预览',
        component: () => import('../views/preview/preview'),
        meta: {
          keepAlive: true,
        }
      },
      {
        path: '/PM/Po/PoAdd',
        name: '添加订单',
        component: () => import('../views/PM/Po/PoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/Po/PoEdit',
        name: '修改订单',
        component: () => import('../views/PM/Po/PoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/Po/PoDetail',
        name: '订单详情',
        component: () => import('../views/PM/Po/PoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/Po/PoHistory',           // 20240915 
        name: '历史订单',
        component: () => import('../views/PM/Po/PoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/SM/So/SoAdd',
        name: '添加合同',
        component: () => import('../views/SM/So/SoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/So/SoEdit',
        name: '修改合同',
        component: () => import('../views/SM/So/SoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/So/SoDetail',
        name: '合同详情',
        component: () => import('../views/SM/So/SoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/So/SoHistory',          // 20240915 
        name: '历史合同',
        component: () => import('../views/SM/So/SoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      //采购物流
      {
        path: '/PM/iLog/iLogAdd',
        name: '新增物流进单',
        component: () => import('../views/PM/iLog/iLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/iLog/iLogEdit',
        name: '修改物流进单',
        component: () => import('../views/PM/iLog/iLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/iLog/iLogDetail',
        name: '进货物流详情',
        component: () => import('../views/PM/iLog/iLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/iLog/iLogHistory',         // 20240915 
        name: '历史进货物流',
        component: () => import('../views/PM/iLog/iLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      //销售物流
      {
        path: '/SM/oLog/oLogAdd',
        name: '新增物流出单',
        component: () => import('../views/SM/oLog/oLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oLog/oLogEdit',
        name: '修改物流出单',
        component: () => import('../views/SM/oLog/oLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oLog/oLogDetail',
        name: '出货物流详情',
        component: () => import('../views/SM/oLog/oLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oLog/oLogHistory',         // 20240915 
        name: '历史出货物流',
        component: () => import('../views/SM/oLog/oLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },

      //公司管理
      {
        path: '/BI/Com/ComAdd',
        name: '添加公司',
        component: () => import('../views/BI/Com/ComApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '公司银行'
        }
      },
      {
        path: '/BI/Com/ComEdit',
        name: '修改公司',
        component: () => import('../views/BI/Com/ComApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '公司银行'
        }
      },
      {
        path: '/BI/Com/ComDetail',
        name: '公司详情',
        component: () => import('../views/BI/Com/ComApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '公司银行'
        }
      },
      {
        path: '/BI/Com/ComHistory',         // 20240915 
        name: '历史公司详情',
        component: () => import('../views/BI/Com/ComApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '公司银行'
        }
      },

      //产品管理
      {
        path: '/BI/Pro/ProAdd',
        name: '添加产品',
        component: () => import('../views/BI/Pro/ProApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '产品'
        }
      },
      {
        path: '/BI/Pro/ProEdit',
        name: '修改产品',
        component: () => import('../views/BI/Pro/ProApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '产品'
        }
      },
      {
        path: '/BI/Pro/ProDetail',
        name: '产品详情',
        component: () => import('../views/BI/Pro/ProApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '产品'
        }
      },
      {
        path: '/BI/Pro/ProHistory',         // 20240915
        name: '历史产品详情',
        component: () => import('../views/BI/Pro/ProApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '产品'
        }
      },
      //库存管理
      {
        path: '/IM/Rec/RecAdd',
        name: '添加入库单',
        component: () => import('../views/IM/Rec/RecApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Rec/RecEdit',
        name: '修改入库单',
        component: () => import('../views/IM/Rec/RecApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Rec/RecDetail',
        name: '入库单详情',
        component: () => import('../views/IM/Rec/RecApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Rec/RecHistory',         // 20240915
        name: '历史入库单',
        component: () => import('../views/IM/Rec/RecApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //出库
      {
        path: '/IM/Ful/FulAdd',
        name: '添加出库单',
        component: () => import('../views/IM/Ful/FulApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Ful/FulEdit',
        name: '修改出库单',
        component: () => import('../views/IM/Ful/FulApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Ful/FulDetail',
        name: '出库单详情',
        component: () => import('../views/IM/Ful/FulApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Ful/FulHistory',         // 20240915
        name: '历史出库单',
        component: () => import('../views/IM/Ful/FulApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //库存
      {
        path: '/IM/Inv/InvAdd',
        name: '添加库存',
        component: () => import('../views/IM/Inv/InvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Inv/InvEdit',
        name: '修改库存',
        component: () => import('../views/IM/Inv/InvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Inv/InvDetail',
        name: '库存详情',
        component: () => import('../views/IM/Inv/InvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //调拨
      {
        path: '/IM/Adj/AdjAdd',
        name: '添加调拨',
        component: () => import('../views/IM/Adj/AdjApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Adj/AdjEdit',
        name: '修改库存',
        component: () => import('../views/IM/Adj/AdjApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Adj/AdjDetail',
        name: '调拨详情',
        component: () => import('../views/IM/Adj/AdjApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //样品合同
      {
        path: '/IM/Sam/SamAdd',
        name: '添加样品合同',
        component: () => import('../views/IM/Sam/SamApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Sam/SamEdit',
        name: '修改样品合同',
        component: () => import('../views/IM/Sam/SamApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/Sam/SamDetail',
        name: '样品合同详情',
        component: () => import('../views/IM/Sam/SamApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //样品库存
      {
        path: '/IM/sInv/sInvAdd',
        name: '添加样品库存',
        component: () => import('../views/IM/sInv/sInvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/sInv/sInvEdit',
        name: '修改样品库存',
        component: () => import('../views/IM/sInv/sInvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      {
        path: '/IM/sInv/sInvDetail',
        name: '样品库存详情',
        component: () => import('../views/IM/sInv/sInvApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '库存管理'
        }
      },
      //采购文档
      {
        path: '/PM/iDoc/iDocHistory',         // 20240915
        name: '历史采购文档',
        component: () => import('../views/PM/iDoc/iDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购文档'
        }
      },
      {
        path: '/PM/iDoc/iDocDetail',
        name: '文档详情',
        component: () => import('../views/PM/iDoc/iDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购文档'
        }
      },
      {
        path: '/PM/iDoc/iDocEdit',
        name: '修改文档',
        component: () => import('../views/PM/iDoc/iDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购文档'
        }
      },
      {
        path: '/PM/iDoc/iDocAdd',
        name: '添加文档',
        component: () => import('../views/PM/iDoc/iDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购文档'
        }
      },
      //销售文档  -->  产品文档
      {
        path: '/SM/oDoc/oDocHistory',         // 20240915
        name: '历史产品文档',
        component: () => import('../views/SM/oDoc/oDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oDoc/oDocDetail',
        name: '产品文档详情',
        component: () => import('../views/SM/oDoc/oDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oDoc/oDocEdit',
        name: '修改产品文档',
        component: () => import('../views/SM/oDoc/oDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/oDoc/oDocAdd',
        name: '添加产品文档',
        component: () => import('../views/SM/oDoc/oDocApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      //采购货款
      {
        path: '/PM/Exp/PoExpDetail',
        name: '采购货款详情',
        component: () => import('../views/PM/Exp/PoExpApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/Exp/PoExpEdit',
        name: '修改采购货款',
        component: () => import('../views/PM/Exp/PoExpApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      {
        path: '/PM/Exp/PoExpAdd',
        name: '添加采购货款',
        component: () => import('../views/PM/Exp/PoExpApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购管理'
        }
      },
      //销售收入
      {
        path: '/SM/Rev/SoRevDetail',
        name: '销售收入详情',
        component: () => import('../views/SM/Rev/SoRevApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/Rev/SoRevEdit',
        name: '修改销售收入',
        component: () => import('../views/SM/Rev/SoRevApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      {
        path: '/SM/Rev/SoRevAdd',
        name: '添加销售收入',
        component: () => import('../views/SM/Rev/SoRevApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售管理'
        }
      },
      //采购费用
      {
        path: '/PM/fee/PoFeeAdd',
        name: '添加采购费用',
        component: () => import('../views/PM/fee/PoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购费用'
        }
      },
      {
        path: '/PM/fee/PoFeeEdit',
        name: '修改采购费用',
        component: () => import('../views/PM/fee/PoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购费用'
        }
      },
      {
        path: '/PM/fee/PoFeeDetail',
        name: '采购费用详情',
        component: () => import('../views/PM/fee/PoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购费用'
        }
      },
      {
        path: '/PM/fee/PoFeeHistory',         // 20240915
        name: '历史采购费用',
        component: () => import('../views/PM/fee/PoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '采购费用'
        }
      },
      //销售费用 20230709 cc
      {
        path: '/SM/fee/SoFeeAdd',
        name: '添加销售费用',
        component: () => import('../views/SM/fee/SoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售费用'
        }
      },
      {
        path: '/SM/fee/SoFeeEdit',
        name: '修改销售费用',
        component: () => import('../views/SM/fee/SoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售费用'
        }
      },
      {
        path: '/SM/fee/SoFeeDetail',
        name: '销售费用详情',
        component: () => import('../views/SM/fee/SoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售费用'
        }
      },
      {
        path: '/SM/fee/SoFeeHistory',         // 20240915
        name: '历史销售费用',
        component: () => import('../views/SM/fee/SoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '销售费用'
        }
      },

      //其它合同
      {
        path: '/AM/Ao/AoAdd',
        name: '添加其它合同',
        component: () => import('../views/AM/Ao/AoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/Ao/AoEdit',
        name: '修改其它合同',
        component: () => import('../views/AM/Ao/AoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/Ao/AoDetail',
        name: '其它合同详情',
        component: () => import('../views/AM/Ao/AoApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      //其它物流
      {
        path: '/AM/aLog/aLogAdd',
        name: '添加其它物流',
        component: () => import('../views/AM/aLog/aLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/aLog/aLogEdit',
        name: '修改其它物流',
        component: () => import('../views/AM/aLog/aLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/aLog/aLogDetail',
        name: '其它物流详情',
        component: () => import('../views/AM/aLog/aLogApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      //其它费用
      {
        path: '/AM/fee/AoFeeAdd',
        name: '添加其它费用',
        component: () => import('../views/AM/fee/AoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/fee/AoFeeEdit',
        name: '修改其它费用',
        component: () => import('../views/AM/fee/AoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },
      {
        path: '/AM/fee/AoFeeDetail',
        name: '其它费用详情',
        component: () => import('../views/AM/fee/AoFeeApp'),
        meta: {
          keepAlive: true, //需要缓存
          parentName: '其它管理'
        }
      },

    ],
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
