import { createStore } from 'vuex'
import router from '@/router'
export default createStore({
  state: {
    info:{},
    memo:false,
    hasRoutes:false,
    editableTabsValue:'/index',
    editableTabs:[
      {
        title:'首页',
        name:'/index',
        isChange:false,
        pk:null
      }
    ],
    removeTabValue:null
  },
  getters: {
    GET_TOKEN:state => {
      return sessionStorage.getItem("token")
    },
    GET_TOKENTYPE:state => {
      return sessionStorage.getItem("tokenType")
    },
    GET_MENULIST:state => {
      return JSON.parse(sessionStorage.getItem("menulist"))
    },
    GET_PERMS:state=>{
      return JSON.parse(sessionStorage.getItem("perms"))
    },
    GET_USERINFO:state => {
      return JSON.parse(sessionStorage.getItem("userinfo"))
    },
    GET_SYSTEMPHASE:state => {
      return JSON.parse(sessionStorage.getItem("SystemPhase"))
    },
    GET_INVENTORYOWNERSHIP:state => {
      return JSON.parse(sessionStorage.getItem("InventoryOwnership"))
    },
    GET_TABS: state => {
      return state.editableTabs;
    }
  },
  mutations: {
    SET_TOKEN:(state,token)=>{
      // console.log("3 token to store:", token);
      sessionStorage.setItem("token", token);
      // console.log("4 token after store:", sessionStorage.getItem("token"));
    }, 
    RESET_TOKEN:(state)=>{
      // console.log(" RESET_TOKEN called")
      //state.token=''
      sessionStorage.setItem("token", "")
    },
    SET_TOKENTYPE:(state,tokenType)=>{
      // console.log("3 type to store:", tokenType);
      sessionStorage.setItem("tokenType", tokenType);
      //console.log("4 type after store:", sessionStorage.getItem("tokenType"));
    }, 
    SET_MENULIST:(state,menulist)=>{
      sessionStorage.setItem("menulist", JSON.stringify(menulist));
    },
    SET_USERINFO:(state,userinfo)=>{
      sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
    },
    SET_SYSTEMPHASE:(state,SystemPhase)=>{
      sessionStorage.setItem("SystemPhase", SystemPhase);
    },
    SET_INVENTORYOWNERSHIP:(state,InventoryOwnership)=>{
      sessionStorage.setItem("InventoryOwnership", InventoryOwnership);
    },
    SET_ROUTES_STATE:(state,hasRoutes)=>{
      state.hasRoutes=hasRoutes
    },
    SET_PERMS:(state,perms)=>{
      sessionStorage.setItem("perms", JSON.stringify(perms))
    },
    SET_PAGE_STATE: (state, [isChange, pk = null]) => {
      // console.log("state.editableTabsValue--->", state.editableTabsValue);
      state.editableTabs.forEach((tab, index) => { 
        if (tab.name === state.editableTabsValue) {
          //console.log('pk===========', pk)
          tab.isChange = isChange;
          tab.pk = pk;
          return;
        }
        //console.log("是否继续执行")
      })
    },
    ADD_TABS:(state, tab)=>{
      if(state.editableTabs.findIndex(e=>e.name===tab.path)===-1){  
        state.editableTabs.push({
          title:tab.name,
          name: tab.path,
          isChange:false,
          pk:null
        })
        // console.log(tab.name)      
      }
      state.editableTabsValue=tab.path
    },
    RESET_TABS:(state)=>{      
      state.editableTabsValue='/index'
      state.editableTabs=[
        {
          title:'首页',
          name:'/index',
          isChange:false,
          pk:null
        }
      ]
    },
    REMOVE_TAB: (state, tab) => {
      state.removeTabValue = tab
    }
  },
  actions: {
    // 安全退出
    logout() {
      // console.log('logout of store !!!!!!!!')
      store.commit('RESET_TOKEN')
      window.sessionStorage.clear();
      this.state.hasRoutes=false
      router.replace("/login")
    }
  },
  modules: {
  }
})
