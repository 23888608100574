<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>

<script setup>
import store from '@/store'
import { ref, watch} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElConfigProvider } from 'element-plus';  //引入方法

import zhCn from 'element-plus/lib/locale/lang/zh-cn';  //引入中文包
const route=useRoute();
const router=useRouter();
const whitePath=['/login','/index','/']
const locale = ref(zhCn);

watch(route,(to,from)=>{
  //console.log("to "+to.name)
  //console.log(to.path)

  if (whitePath.indexOf(to.path)===-1) {
    //console.log("to.path = "+to.path)
    let obj = {
      name: to.name,
      path: to.path
    }

    store.commit("ADD_TABS", obj)
  }

}, {deep:true,immediate:true})

</script>

<style>
/* 设置全局样式 */

html,body,#app{
  height: 100%;
}
/* span{
  font-family: 仿宋;
  padding-left: 11px;
} */

.app-container{
  padding: 30px;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
  min-width: 60px!important;
  padding-left: 0px !important;
}
</style>